import { Component, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css'],
  host: { class: 'centered-page-content' },
})
export class TeamComponent implements OnInit {
  public personalData = [
    {
      id: 'cs',
      fullName: 'Conny Schumann',
      jobTitle: 'Assistenz/ Professionelle Zahnreinigung/ Recall',
      imageUrl: 'assets/img/conny_new.jpg',
    },
    {
      id: 'mc',
      fullName: 'Manuela Causemann',
      jobTitle: 'Assistenz/ Professionelle Zahnreinigung/ Materialverwaltung',
      imageUrl: 'assets/img/manuela_new.jpg',
    },
    {
      id: 'as',
      fullName: 'Dipl.-Stom. Annette Sedlmair',
      jobTitle:
        '- Studium der Zahnmedizin Leipzig (1979-84)\n- Approbation als Zahnärztin 1984\n-Fachzahnärztin für Kieferorthopädie 1990',
      imageUrl: 'assets/img/annette_new.jpg',
    },
    {
      id: 'jw',
      fullName: 'Jeannette Wallat',
      jobTitle: 'Abrechnung / Verwaltung',
      imageUrl: 'assets/img/jeannette_new.jpg',
    },
    {
      id: 'ts',
      fullName: 'Dr. med. Thomas Sedlmair',
      jobTitle:
        '- Studium der Zahnmedizin Leipzig (1980-85)\n- Approbation als Zahnarzt 1985\n- Fachzahnarzt für Allgemeine Stomatologie 1990\n- Promotion 1990',
      imageUrl: 'assets/img/thomas_new.jpg',
    },
  ];

  public showPersonalData = false;

  public nameOfHoveredPersonal: string = '';

  public descriptionOfHoveredPersonal: string = '';

  public availableWidthInPixels: number = 0;

  public playIcon: IconDefinition = faPlay;

  private resizeObservable: Observable<Event>;
  private resizeSubscription: Subscription;

  constructor() {}

  ngOnInit(): void {
    // subscrihbe to future window resizes
    this.resizeObservable = fromEvent(window, 'resize');
    this.resizeSubscription = this.resizeObservable.subscribe((evt) => {
      this.availableWidthInPixels = window.innerWidth;
    });

    // get initial window size
    this.availableWidthInPixels = window.innerWidth;
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
  }

  displayPersonalData(personalId: string): void {
    this.showPersonalData = true;
    const hoveredPersonal = this.personalData.filter(
      (val) => val.id == personalId
    )[0];
    console.debug(hoveredPersonal);

    if (hoveredPersonal) {
      this.nameOfHoveredPersonal = hoveredPersonal.fullName;
      this.descriptionOfHoveredPersonal = hoveredPersonal.jobTitle;
    }
  }

  hidePersonalData(): void {
    this.showPersonalData = false;
    this.nameOfHoveredPersonal = '';
    this.descriptionOfHoveredPersonal = '';
  }
}
