<div class="service-card-header" [ngClass]="{'text-only-header' : serviceDescription == '', 'header-with-button' : serviceDescription != ''}">

    <h2 class="sub-heading">{{serviceHeading}}</h2>

    <button [matTooltip]="textToggleButtonTooltip" matTooltipClass="normal-text custom-tooltip" *ngIf="serviceDescription != ''" id="textToggleButton" class="normal-text" (click)="toggleServiceDescription()" mat-icon-button color="primary">{{textToggleButtonLabel}}</button>

</div>

<div class="service-card-content">

    <img *ngIf="!isShowingText"  [src]="servicePictureUrl" class="service-picture" [attr.alt]="'Symbolbild ' + serviceHeading">

    <p *ngIf="isShowingText" class="normal-text card-text">{{serviceDescription}}</p>

</div>