import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsComponent } from './news.component';
import { NewsArticleComponent } from './news-article/news-article.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    NewsComponent,
    NewsArticleComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class NewsModule { }
