import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button'; 
import {MatToolbarModule} from '@angular/material/toolbar';
import { MenuBarComponent } from './components/menu-bar/menu-bar.component';
import {MatMenuModule} from '@angular/material/menu'; 
import {MatIconModule} from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {MatExpansionModule} from '@angular/material/expansion'; 
import {MatDividerModule} from '@angular/material/divider';
import { DividerComponent } from './components/divider/divider.component';
import {MatDialogModule} from '@angular/material/dialog'; 
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatRadioModule} from '@angular/material/radio'; 
import { FormsModule } from '@angular/forms';
import { ArrayStringFilterPipe } from './pipes/ArrayStringFilterPipe';
import {MatTooltipModule} from '@angular/material/tooltip'; 


@NgModule({
  declarations: [
    MenuBarComponent,
    DividerComponent,
    ArrayStringFilterPipe

  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatMenuModule,
    FontAwesomeModule,
    RouterModule,
    BrowserAnimationsModule,
    MatTooltipModule
  ],
  exports: [
    MatButtonModule,
    MenuBarComponent,
    MatMenuModule,
    MatIconModule,
    FontAwesomeModule,
    MatExpansionModule,
    MatDividerModule,
    DividerComponent,
    MatDialogModule,
    BrowserAnimationsModule,
    MatRadioModule,
    FormsModule,
    ArrayStringFilterPipe,
    CommonModule,
    MatTooltipModule
  ]
})
export class SharedModule { }
