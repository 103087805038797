<div id="pageLayout" class="page-layout parallax-background" [style]="'background-image: url(' + backgroundImageUrl + ');'">

    <div id="pageHeader" class="page-header">

        <app-menu-bar [title]="currentPageHeading" [isSubpageMenuBar]="isSubPage" style="width: 100%;"></app-menu-bar>

    </div>
    
    <div id="pageContent" class="page-content vertical-layout">

        <router-outlet></router-outlet>

    </div>

</div>