import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit,OnDestroy {

  title = 'SedlmairWebsite';

  public currentPageHeading: string = "Zahnärtzliche Gemeinschaftspraxis Sedlmair";
  public isSubPage: boolean = false;
  public backgroundImageUrl: string = "assets/img/background/nature_background.jpg";

  private routeChangeSubscription: Subscription;
  
  constructor(private router: Router) {

  }

  ngOnInit(): void {

    /* subscribe to route changes so that the menu can get updated accordingly */
    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {

        let currentRelativeUrl = event.url;

        switch(currentRelativeUrl) {

          case "/":

          case "/startseite": {
            this.currentPageHeading = "Zahnärztliche Gemeinschaftspraxis Dr. Sedlmair";
            this.isSubPage = false;
            this.backgroundImageUrl = "assets/img/background/nature_background.jpg";
            break;
          }

          case "/aktuelles": {
            this.currentPageHeading = "Aktuelles";
            this.isSubPage = true;
            this.backgroundImageUrl = "assets/img/background/facility_outside_gate.JPG";
            break;
          }

          case "/praxis": {
            this.currentPageHeading = "Praxis";
            this.isSubPage = true;
            this.backgroundImageUrl = "assets/img/background/facility_outside.JPG";
            break;
          }

          case "/team": {
            this.currentPageHeading = "Team";
            this.isSubPage = true;
            this.backgroundImageUrl = "assets/img/background/nature_background.jpg";
            break;
          }

          case "/kontakt": {
            this.currentPageHeading = "Kontakt";
            this.isSubPage = true;
            this.backgroundImageUrl = "assets/img/background/contact.JPG";
            break;
          }

          case "/sprechzeiten": {
            this.currentPageHeading = "Sprechzeiten";
            this.isSubPage = true;
            this.backgroundImageUrl = "assets/img/background/facility_outside_alt.JPG";
            break;
          }

          case "/leistungen": {
            this.currentPageHeading = "Leistungen";
            this.isSubPage = true;
            this.backgroundImageUrl = "assets/img/background/services_general.JPG";
            break;
          }

          case "/leistungen/zahnheilkunde": {
            this.currentPageHeading = "Allgemeine Zahnheilkunde";
            this.isSubPage = true;
            this.backgroundImageUrl = "assets/img/background/services_general.JPG";
            break;
          }

          case "/leistungen/kieferorthopaedie": {
            this.currentPageHeading = "Kieferorthopädie";
            this.isSubPage = true;
            this.backgroundImageUrl = "assets/img/background/services_general.JPG";
            break;
          }

          case "/leistungen/faq": {
            this.currentPageHeading = "Häufig gestellte Fragen";
            this.isSubPage = true;
            this.backgroundImageUrl = "assets/img/background/sunflowers.JPG";
            break;
          }

          case "/impressum": {
            this.currentPageHeading = "Impressum";
            this.isSubPage = true;
            this.backgroundImageUrl = "assets/img/background/imprint.JPG";
            break;
          }

          default: {
            this.currentPageHeading = "n/a";
            this.isSubPage = true;
            this.backgroundImageUrl = "";
            break;
          }
        }
      }
    })
  }

  ngOnDestroy() {

    this.routeChangeSubscription?.unsubscribe();

  }

}
