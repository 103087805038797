<div [matTooltip]="tooltipText" matTooltipClass="normal-text custom-tooltip" matTooltipPosition="right" matTooltipTouchGestures="off" class="news-article-box screen-dependend blurry-element-with-fallback rounded-element-with-border" (click)="toggleFullTextVisibility()">

    <div>

        <h2 class="sub-heading news-article-title">{{newsArticle.date | date:'longDate'}} {{" - " + newsArticle.title}}</h2>
    
    </div>
    
    <br>

    <p *ngIf="!showFullText" class="normal-text">Hier klicken, um weiterzulesen ...</p>

    <!--<p [@ngIfAnimation] *ngIf="showFullText"  class="normal-text">{{newsArticle.text}}</p>-->

    <div [@ngIfAnimation] *ngIf="showFullText" class="normal-text" [innerHTML]="newsArticle.text"></div>

</div>