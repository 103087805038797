<div class="horizontal-layout contact-container rounded-element-with-border blurry-element-with-fallback">

    <h2 class="sub-heading centered-text">Dresden Pillnitz</h2>

    <p class="normal-text centered-text">
        Maille-Bahn 4<br>
        01326 Dresden
    </p>

    <a href="tel:+493512610275" title="Klicken, um einen Anruf zu starten" class="horizontal-layout primary-axis-centered secondary-axis-centered phone-section">

        <fa-icon class="page-icon fa-3x" [icon]="phoneIcon"></fa-icon>

        <p class="normal-text centered-text">0351 - 2610 275</p>

    </a>

    <a href="mailto:kontakt@praxis-dr-sedlmair.de" title="Klicken, um eine E-Mail zu schreiben" class="horizontal-layout primary-axis-centered secondary-axis-centered mail-section">

        <fa-icon class="page-icon fa-3x" [icon]="mailIcon"></fa-icon>

        <p class="normal-text centered-text">kontakt@praxis-dr-sedlmair.de</p>

    </a>


    <iframe 
        class="card-frame" 
        allowfullscreen="" loading="lazy"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1774.9334911797866!2d13.860545219016782!3d51.013658317904884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4709c7f119f35821%3A0x290f9e924908e986!2sZahn%C3%A4rztliche%20Gemeinschaftspraxis%20Dr.%20Sedlmair%20f%C3%BCr%20Zahnheilkunde%20%26%20Kieferorthop%C3%A4die!5e0!3m2!1sde!2sde!4v1637849401389!5m2!1sde!2sde"
        >
    </iframe>
    
</div>

<div class="horizontal-layout contact-container rounded-element-with-border blurry-element-with-fallback">
    
    <h2 class="sub-heading centered-text">Zweigpraxis Kieferorthopädie Stolpen</h2>

    <p class="normal-text centered-text">
        Rudolf-Breitscheid-Str. 9<br>
        01833  Stolpen
    </p>

    <a href="tel:+493512610275" title="Klicken, um einen Anruf zu starten" class="horizontal-layout primary-axis-centered secondary-axis-centered phone-section">

        <fa-icon class="page-icon fa-3x" [icon]="phoneIcon"></fa-icon>

        <p class="normal-text centered-text">Festnetz: 0351 - 2610 275</p>

    </a>

    <a href="tel:+4915151943410" title="Klicken, um einen Anruf zu starten" class="horizontal-layout primary-axis-centered secondary-axis-centered phone-section">

        <fa-icon class="page-icon fa-3x" [icon]="phoneIcon"></fa-icon>

        <p class="normal-text centered-text">Mobil: 01515 - 1943 410</p>

    </a>

    <a href="mailto:kontakt@praxis-dr-sedlmair.de" title="Klicken, um eine E-Mail zu schreiben" class="horizontal-layout primary-axis-centered secondary-axis-centered mail-section">

        <fa-icon class="page-icon fa-3x" [icon]="mailIcon"></fa-icon>

        <p class="normal-text centered-text">kontakt@praxis-dr-sedlmair.de</p>

    </a>

    <iframe class="card-frame" 
        allowfullscreen="" loading="lazy"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10033.262424026318!2d14.08108!3d51.04726!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x74bebb42f8951e54!2sKieferorthop%C3%A4die%20Dipl.-Stom.%20Annette%20Sedlmair!5e0!3m2!1sde!2sde!4v1646777698880!5m2!1sde!2sde"
        >
    </iframe>

</div>