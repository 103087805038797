<div id="pageInteractionBar" class="rounded-element-with-border blurry-element-with-fallback horizontal-layout secondary-axis-centered">

    <button [routerLink]="'/leistungen/zahnheilkunde'" mat-raised-button>
        Zur Zahnheilkunde
    </button>  

    <button [routerLink]="'/leistungen/kieferorthopaedie'" mat-raised-button>
        Zur Kieferorthopädie
    </button>  
    
    <mat-radio-group class="faq-radio-group white-text" [(ngModel)]="chosenQuestionType">

        <mat-radio-button class="faq-radio-group-button white-text" *ngFor="let faqQuestionType of faqQuestionTypes" [value]="faqQuestionType.value">
            {{faqQuestionType.label}}
        </mat-radio-button>

    </mat-radio-group>
    
</div>

<div id="questionAnswerPairs" class="vertical-layout primary-axis-centerd secondary-axis-centered">

    <question-answer-pair class="quest-answer-pair" *ngFor="let questionAnswerPair of faqPairs | arrayStringFilter:chosenQuestionType" [question]="questionAnswerPair.question" [answer]="questionAnswerPair.answer"></question-answer-pair>

    <!--if there are not FAQ pairs for the selected question type-->
    <div *ngIf="(faqPairs | arrayStringFilter:chosenQuestionType).length == 0" class="rounded-element-with-border blurry-element-with-fallback">
        <p class="normal-text white-text centered-text">Für den ausgewählten Fragetyp sind leider keine 'Häufig gestellten Fragen' verfügbar.</p>
    </div>

</div>

