<div *ngIf="useColumnLayout==false" id="circleLayout">

    <img id="circleCenter" alt="Praxis Logo" src="assets/img/logo_neu.jpg"  class="rounded-element-with-border blurry-element-with-fallback">

    
    <div *ngFor="let facilityElement of facilityElements" [attr.id]="'circleElement_' + facilityElement.id" 
        class="rounded-element-with-border blurry-element-with-fallback facility-element circle-element" (click)="showFacilityPartAsDialog(facilityElement.id)">

        <p class="heading">{{facilityElement.facilityPartName}}</p>

    </div>
    

</div>

<div *ngIf="useColumnLayout==true" id="verticalLayout" class="vertical-layout main-axis-centered secondary-axis-centered">

    <img src="assets/img/logo_neu.jpg"  class="rounded-element-with-border blurry-element-with-fallback">

    <div [attr.id]="'mobileFacilityElement_' + facilityElement.id" *ngFor="let facilityElement of facilityElements" class="rounded-element-with-border blurry-element-with-fallback facility-element list-element vertical-layout primary-axis-centered secondary-axis-centered" (click)="showFacilityPart(facilityElement.id)">

        <p class="heading">{{facilityElement.facilityPartName}}</p>

        <div [@ngIfAnimation] *ngIf="currentlyActiveFacilityElement != null && currentlyActiveFacilityElement.id == facilityElement.id">

            <img class="facility-part-image" [attr.alt]="'Bild ' + currentlyActiveFacilityElement.facilityPartName" [src]="currentlyActiveFacilityElement.imagePath">

            <p class="normal-text centered-text">{{currentlyActiveFacilityElement.description}}</p>

        </div>

    </div>

</div>