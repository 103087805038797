import { Component, OnInit } from '@angular/core';
import { Service } from '../Service';

@Component({
  selector: 'app-dentistry-services',
  templateUrl: './dentistry-services.component.html',
  styleUrls: ['./dentistry-services.component.css']
})
export class DentistryServicesComponent implements OnInit {

  public services: Array<Service> = [

    {
      serviceHeading: "Keramik- und Goldfüllungen",
      serviceDescription: "",
      servicePictureUrl: "assets/img/services/dentistry/teeth_filling.jpg"
    },
    {
      serviceHeading: "Ästhetischer Zahnersatz",
      serviceDescription: "",
      servicePictureUrl: "assets/img/services/dentistry/tooth_replacement.jpg"
    },
    {
      serviceHeading: "Zahnersatz auf Implantaten",
      serviceDescription: "",
      servicePictureUrl: "assets/img/services/dentistry/implants.jpg"
    },
    {
      serviceHeading: "Kinderzahnheilkunde",
      serviceDescription: "",
      servicePictureUrl: "assets/img/services/dentistry/children_care.jpg"
    },
    {
      serviceHeading: "Prophylaxe für Kinder und Erwachsene",
      serviceDescription: "",
      servicePictureUrl: "assets/img/services/dentistry/dental_care.jpg"
    },
    {
      serviceHeading: "Recall (Erinnerungsservice)",
      serviceDescription: "",
      servicePictureUrl: "assets/img/services/dentistry/recall.jpg"
    },
    {
      serviceHeading: "Parodontologie",
      serviceDescription: "",
      servicePictureUrl: "assets/img/services/dentistry/paradontitis_care.jpg"
    },
    {
      serviceHeading: "Professionelle Zahnreinigung",
      serviceDescription: "",
      servicePictureUrl: "assets/img/services/dentistry/placeholder.jpg"
    },
    {
      serviceHeading: "Prothesenreinigung (maschinell)",
      serviceDescription: "",
      servicePictureUrl: "assets/img/services/dentistry/waterdrop.jpg"
    },
    {
      serviceHeading: "Zahnärtzliche Chirurgie",
      serviceDescription: "",
      servicePictureUrl: "assets/img/services/dentistry/x_ray.jpg"
    },
    {
      serviceHeading: "Beratung",
      serviceDescription: "",
      servicePictureUrl: "assets/img/services/dentistry/consulting.jpg"
    },   
    {
      serviceHeading: "Wurzelbehandlungen",
      serviceDescription: "",
      servicePictureUrl: "assets/img/services/dentistry/endodontic_treatment.jpg"
    }, 
    {
      serviceHeading: "Aufbissschienen",
      serviceDescription: "",
      servicePictureUrl: "assets/img/services/dentistry/rail_treatment.jpg"
    },
  ];

  constructor() { }

  ngOnInit(): void {

    this.services = this.services.sort((serviceA,serviceB) => {
      return serviceA.serviceHeading.localeCompare(serviceB.serviceHeading);
    })

  }

}
