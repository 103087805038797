<div
  id="mobileTeamLayout"
  *ngIf="availableWidthInPixels < 1000"
  class="vertical-layout main-axis-centered secondary-axis-centered"
>
  <personal-box
    *ngFor="let personal of personalData"
    [fullName]="personal.fullName"
    [jobTitle]="personal.jobTitle"
    [imageUrl]="personal.imageUrl"
  ></personal-box>
</div>

<div
  *ngIf="availableWidthInPixels >= 1000"
  id="teamImageContainer"
  class="blurry-element-with-fallback rounded-element-with-border"
>
  <img id="teamImage" src="assets/img/team_2024_2_small.jpg" alt="Bild Team" />

  <button
    mat-icon-button
    class="personClicker personClicker-one"
    (mouseenter)="displayPersonalData('as')"
    (mouseleave)="hidePersonalData()"
  >
    <fa-icon [icon]="playIcon"></fa-icon>
  </button>

  <button
    mat-icon-button
    class="personClicker personClicker-two"
    (mouseenter)="displayPersonalData('mc')"
    (mouseleave)="hidePersonalData()"
  >
    <fa-icon [icon]="playIcon"></fa-icon>
  </button>

  <button
    mat-icon-button
    class="personClicker personClicker-four"
    (mouseenter)="displayPersonalData('jw')"
    (mouseleave)="hidePersonalData()"
  >
    <fa-icon [icon]="playIcon"></fa-icon>
  </button>

  <button
    mat-icon-button
    class="personClicker personClicker-five"
    (mouseenter)="displayPersonalData('cs')"
    (mouseleave)="hidePersonalData()"
  >
    <fa-icon [icon]="playIcon"></fa-icon>
  </button>

  <button
    mat-icon-button
    class="personClicker personClicker-six"
    (mouseenter)="displayPersonalData('ts')"
    (mouseleave)="hidePersonalData()"
  >
    <fa-icon [icon]="playIcon"></fa-icon>
  </button>

  <div id="persoalInformationBox">
    <p *ngIf="!showPersonalData" class="normal-text">
      Wählen Sie eine Person auf dem Foto aus.
    </p>

    <h3 *ngIf="showPersonalData" class="heading person-name centered-text">
      {{ nameOfHoveredPersonal }}
    </h3>

    <p *ngIf="showPersonalData" class="normal-text">
      {{ descriptionOfHoveredPersonal }}
    </p>
  </div>
</div>
