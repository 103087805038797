import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { WelcomePageCardComponent } from './components/welcome-page/welcome-page-card/welcome-page-card.component';
import { FacilityComponent } from './components/facility/facility.component';
import { TeamComponent } from './components/team/team.component';
import { ServicesComponent } from './components/services/services.component';
import { OfficeHoursComponent } from './components/office-hours/office-hours.component';
import { ContactComponent } from './components/contact/contact.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { NewsModule } from './components/news/news.module';
import { LOCALE_ID } from '@angular/core';
import localGer from '@angular/common/locales/de'
import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DentistryServicesComponent } from './components/services/dentistry-services/dentistry-services.component';
import { OrthodontiaServicesComponent } from './components/services/orthodontia-services/orthodontia-services.component';
import { FrequentlyAskedQuestionsComponent } from './components/services/frequently-asked-questions/frequently-asked-questions.component';
import { ServicesCardComponent } from './components/services/services-card/services-card.component';
import { QuestionAnswerPairComponent } from './components/services/frequently-asked-questions/question-answer-pair/question-answer-pair.component';
import { PersonalBoxComponent } from './components/team/personal-box/personal-box.component';
import { FacilityPartShowroomComponent } from './components/facility/facility-part-showroom/facility-part-showroom.component';
import { ServicesGridComponent } from './components/services/services-grid/services-grid.component';

registerLocaleData(localGer);

@NgModule({
  declarations: [
    AppComponent,
    WelcomePageComponent,
    WelcomePageCardComponent,
    FacilityComponent,
    TeamComponent,
    ServicesComponent,
    OfficeHoursComponent,
    ContactComponent,
    ImprintComponent,
    DentistryServicesComponent,
    OrthodontiaServicesComponent,
    FrequentlyAskedQuestionsComponent,
    ServicesCardComponent,
    QuestionAnswerPairComponent,
    PersonalBoxComponent,
    FacilityPartShowroomComponent,
    ServicesGridComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NewsModule,
    HttpClientModule,
  ],
  providers: [{provide: LOCALE_ID, useValue: 'de-DE' },
              { provide: LocationStrategy, useClass: PathLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
