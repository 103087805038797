<div id="welcomeBanner" class="rounded-element-with-border blurry-element-with-fallback">

    <h1 class="sub-heading welcome-text centered-text">Herzlich Willkommen in unserer Praxis im Grünen.</h1>

    <h1 class="sub-heading welcome-text centered-text">Wir laden Sie zu einem Rundgang ein.</h1>

</div>

<div id="menuGrid">

    <welcome-page-card *ngFor= "let card of cards" [routerLink]="card.link"   [attr.id]="card.id" [attr.title]="card.tooltip" [title] = "card.title"></welcome-page-card>  

</div>
