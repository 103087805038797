<div [matTooltip]="tooltipText" matTooltipClass="normal-text custom-tooltip" matTooltipPosition="right" matTooltipTouchGestures="off" class="question-answer-pair vertical-layout blurry-element-with-fallback rounded-element-with-border" (click)="toggleAnswerVisibility()" title="Klicken für die Antwort ...">

    <h2 class="sub-heading centered-text">{{question}}</h2>

    <div [@ngIfAnimation] *ngIf="isExpanded">

        <divider></divider>

        <p class="normal-text centered-text">{{answer}}</p>

    </div>

</div>