import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'welcome-page-card',
  templateUrl: './welcome-page-card.component.html',
  styleUrls: ['./welcome-page-card.component.css']
})
export class WelcomePageCardComponent implements OnInit {

  public cardClasses: string = "my-card blurry-element-with-fallback";

  constructor() { }

  ngOnInit(): void {
  }

  @Input() title: String;

  onMouseEnter() {
    this.cardClasses = "my-card blurry-element-with-fallback selected-card"
  }

  onMouseLeave() {
    this.cardClasses = "my-card blurry-element-with-fallback"
  }

}
