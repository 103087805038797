<div id="imprint" class="blurry-element-with-fallback rounded-element-with-border">

    <div class="imprint-part">
        
        <p class="normal-text">
            Angaben gem. § 6 Teledienstgesetz
        </p>

    </div>

    <div class="imprint-part">

        <h2 class="sub-heading">
            Name und Anschrift der Zulassung:
        </h2>

        <p class="normal-text">
            Zahnärztliche Gemeinschaftspraxis<br>
            Dr. med. Thomas Sedlmair<br>
            Fachzahnarzt für Allgemeine Stomatologie<br>
            Maille-Bahn 4, 01326 Dresden<br>
            Tel.: 0351 - 2610275
            
            <br><br>

            und

            <br><br>

            Dipl.-Stom. Annette Sedlmair<br>
            Fachzahnärztin für Kieferorthopädie<br>
            Maille-Bahn 4, 01326 Dresden<br>
            Tel.: 0351 - 2610275<br>
            Zweigpraxis: Rudolf-Breitscheid-Str. 9, 01833 Stolpen<br>
            Tel.: 01515 - 1943410<br>
        </p>

    </div>

    <div class="imprint-part">
        <p class="normal-text">Steuernummer: 201/154/06060</p>
    </div>

    <div class="imprint-part">

        <h2 class="sub-heading">
            Angabe zur zuständigen Aufsichtsbehörde:
        </h2>
        <p class="normal-text">
            Kassenzahnärztliche Vereinigung (KZV) Sachsen
            Schützenhöhe 11, 01099 Dresden
        </p>

    </div>

    <div class="imprint-part">

        <h2 class="sub-heading">
            Kammerzugehörigkeit:
        </h2>
        <p class="normal-text">
            Landeszahnärztekammer Sachsen (LZKS)
            Schützenhöhe 11, 01099 Dresden
        </p>

    </div>

    <div class="imprint-part">
        <h2 class="sub-heading">
            Berufsbezeichnung und Staat, in dem die Berufsbezeichnung verliehen worden ist:
        </h2>
        <p class="normal-text">
            Fachzahnarzt für Allgemeine Stomatologie, Deutschland
            Fachzahnarzt für Kieferorthopädie, Deutschland
        </p>
    </div>

    <div class="imprint-part"> 
        <h2 class="sub-heading">
            Berufsrechtliche Regelungen:
        </h2>
        <p class="normal-text">
            - Zahnheilkundegesetz
            - Gebührenordnung für Zahnärzte
            - Berufsordnung für Zahnärzte
        </p>
    </div>

    <div class="imprint-part">
        <p class="normal-text">
            Inhaltlich Verantwortliche: DS A. Sedlmair / Dr. Th. Sedlmair
        </p>
    </div>

    <div class="imprint-part"> 
        <h2 class="sub-heading">
            Haftungsausschluss:
        </h2>
        <p class="normal-text">
            Bei der Erstellung dieser Webseite werden alle Anstrengungen unternommen, die darin enthaltenen Informationen möglichst aktuell, sachlich korrekt und eindeutig zu halten. Trotz aller Sorgfalt kann es jedoch zu unbeabsichtigten Fehlangaben kommen. Wir schließen daher insbesondere jegliche Haftung für Tipp- oder Druckfehler und für die Exaktheit, Vollständigkeit und Aktualität der unserer Webseite enthaltenen Informationen aus.
            Soweit diese Webseite Links auf Webseiten Dritter enthält, übernehmen wir für die dort erhältlichen Informationen, Mitteilungen, Materialien oder Links auf weitere Webseiten keinerlei Verantwortung. Wir erklären ausdrücklich, dass wir uns Inhalte gelinkter Seiten nicht in jedem Fall zu eigen machen.
            Indem Sie unsere Webseite benutzen, tragen Sie die gesamte Gefahr, die mit der Benutzung zusammenhängt, einschließlich sämtlicher Risiken einer Schädigung ihrer Computer, Software, Seele oder Daten durch Viren oder andere Software, die über diese Webseite oder ihren Zugriff darauf übertragen oder aktiviert werden.
            In keinem Fall haften wir für irgendeinen direkten, indirekten, besonderen Begleit- oder Folgeschaden oder entgangenen Gewinn, der sich aus Gebrauch oder Fehlgebrauch von Informationen oder aus unvollständigen Informationen aus unserer Webseite ergeben.
        </p>
    </div>

    <div class="imprint-part"> 
        <h2 class="sub-heading">
            Datenschutzerklärung:
        </h2>
        <p class="normal-text">
            
            Nachfolgend erhalten Sie Informationen über die Erhebung personenbezogener Daten bei Nutzung unserer Internetseite. Zu den personenbezogene Daten gehören alle Daten, die auf Sie persönlich beziehbar sind (z.B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten, IP-Adresse).
            
            <br><br>
            1. Wer ist in unserer Praxis für den Datenschutz verantwortlich?
            <br>
            In unserer Praxis ist für den Datenschutz verantwortlich und steht Ihnen bei Fragen zur Verfügung:
            <br>
            Praxisinhaber: DS A. Sedlmair<br>
            Praxisadresse: Maille-Bahn 4, 01326 Dresden<br>
            Telefon: 0351 - 2610275<br>
            E-Mail: kontakt@praxis-dr-sedlmair.de

            <br><br>
            2. Welche personenbezogenen Daten erheben wir?<br>
            Wir erheben, speichern, nutzen, übermitteln oder löschen folgende personenbezogene Daten:<br>
            - Interessenten unserer Internetseite und Patienten unserer Praxis, die natürliche Personen sind
            <br>
            - Allen anderen natürlichen Personen, die in Kontakt mit unserer Praxis stehen (z.B. Bevollmächtigte von Patienten, Erziehungsberechtigte von Patienten, Mitarbeiter juristischer Personen, Besucher unserer Internetseite)
            <br>

            Personenbezogene Daten von Ihnen werden von uns erhoben, wenn Sie mit uns z.B. per E-Mail oder Telefon in Kontakt treten und einen Behandlungstermin ausmachen wollen. Folgende persönliche Daten verarbeiten wir:
            <br>
            - Persönliche Angaben (z.B. Vor- und Nachnamen, Adresse, Geburtsdatum und -ort, E-Mail-Adresse, Telefonnummer, Versicherungsstatus)
            <br>
            - Daten zu Ihrem Online-Verhalten und -präferenzen (z.B. IP-Adressen, eindeutige Zuordnungsmerkmale mobiler Endgeräte, Daten zu Ihren Besuchen auf unserer Internetseite, Endgeräte, mit denen Sie unsere Internetseite besucht haben)
            <br>
            Angaben zu Kindern erheben wir nur dann, wenn diese durch die Erziehungsberechtigten in unserer Praxis als Patient vorgestellt werden.
            
            <br><br>
            3. Wer erhält ggf. Ihre personenbezogenen Daten übermittelt?<br>
            Falls einzelne Funktionen unserer Internetseite auf beauftragte Dienstleister zurückgreifen, informieren wir Sie in Ziffer 4 dieser Datenschutzerklärung im Detail über die jeweiligen Vorgänge. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.
            
            <br><br>
            4. Welchem Zweck dienen Ihre Daten und mit welcher Rechtfertigung dürfen wir diese nutzen?<br>
            Wenn Sie unsere Internetseite ausschließlich dazu nutzen, sich über unsere Praxis zu informieren, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO):
            <br>
            - IP-Adresse<br>
            - Datum und Uhrzeit der Anfrage<br>
            - Zeitzonendifferenz zur Greenwich Mean Time (GMT)<br>
            - Inhalt der Anforderung (konkrete Seite)<br>
            - Zugriffsstatus/HTTP-Statuscode<br>
            - jeweils übertragene Datenmenge<br>
            - Website, von der die Anforderung kommt<br>
            - Browser<br>
            - Betriebssystem und dessen Oberfläche<br>
            - Sprache und Version der Browsersoftware.<br>
            <br>
            Wollen Sie per E-Mail oder über unser Kontaktformular einen Behandlungstermin vereinbaren oder eine Frage an uns richten, werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihnen einen Behandlungstermin zuweisen oder die Anfrage beantworten zu können. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. b DSGVO).
            
            <br><br>
            5. Welche Rechte haben Sie im Zusammenhang mit dem Datenschutz?
            Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:<br>

            - Recht auf Auskunft,<br>
            - Recht auf Berichtigung oder Löschung,<br>
            - Recht auf Einschränkung der Verarbeitung,<br>
            - Recht auf Widerspruch gegen die Verarbeitung,<br>
            - Recht auf Datenübertragbarkeit.
            
            <br><br>
            6. Wie können Sie sich ggf. beschweren?<br>
            Sie haben die Möglichkeit, sich mit einer Beschwerde im Zusammenhang mit der Datenverarbeitung an die für uns zuständige Datenschutzaufsicht zu wenden:
            <br><br>
            Der Sächsische Datenschutzbeauftragte<br>
            Devrientstraße 1<br>
            01067 Dresden<br>
        </p>

    </div>

    <div class="imprint-part">

        <h2 class="sub-heading">
            Bildnachweise
        </h2>
        
        <p class="normal-text">
            Die auf dieser Website verwendeten Bilder sind entweder selbst angefertigt oder wurden der Plattform pixabay (<a class="break-all-words" href="https://pixabay.com/de/">https://pixabay.com/de/</a>) entnommen. 
            Eine Lizenz oder Bildnachweis ist nach der &quot;Vereinfachte[n] Pixabay Lizenz&quot; (<a class="break-all-words" href="https://pixabay.com/de/service/license/">https://pixabay.com/de/service/license/</a>) normalerweise nicht notwendig.
            Die für diese Website selbst angefertigen Bilder entstammen der Arbeit von Stefan Sedlmair, Annette Sedlmair sowie Thomas Sedlmair. 
        </p>

        <br>

        <p class="normal-text">Einzelbildnachweise</p>

        <ul class="picture-sources-list">
            <li>
                <p class="normal-text">Seite Leistungen, Allgemeine Zahnheilkunde; Autor: Nurrohmad, Memed; Quelle: pixabay.com</p>
            </li>
            <li>
                <p class="normal-text">Seite Häufig gestellte Fragen (FAQ); Autor: Nagy, Csaba; Quelle: pixabay.com</p>
            </li>
            <li>
                <p class="normal-text">Seite Allgemeine Zahnheilkunde, Paradontologie; Autor: Willey, Gareth; Quelle: pixabay.com</p>
            </li>
            <li>
                <p class="normal-text">Seite Allgemeine Zahnheilkunde, Prothesenreinigung; Autor: Socha, Arek; Quelle: pixabay.com</p>
            </li>
            <li>
                <p class="normal-text">Seite Allgemeine Zahnheilkunde, Recall (Erinnerungsservice); Autor: obpia30; Quelle: pixabay.com</p>
            </li>
            <li>
                <p class="normal-text">Seite Allgemeine Zahnheilkunde, Professionelle Zahnreinigung; Autor: G., Thomas (Tho-Ge); Quelle: pixabay.com</p>
            </li>
            <li>
                <p class="normal-text">Seite Kieferorthopädie, Lückenhalter; Autor: User-ID 422737; Quelle: pixabay.com</p>
            </li>



        </ul>

        

        <br>

        <p class="normal-text">Letzter Abruf der in diesem Abschnitt genannten Quellen: 25.11.2021</p>

    </div>

    <div class="imprint-part">

        <h2 class="sub-heading">
            Sonstiges
        </h2>
        
        <p class="normal-text">
            Diese Website wurde von Volker Sedlmair unter Verwendung des Angular Frameworks implementiert. 
        </p>

    </div>

    <div class="imprint-part">
        
        <p class="normal-text">
            letzte Aktualisierung: 25.11.2021 
        </p>

    </div>

</div>




















