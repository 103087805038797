import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.css'],
  host: {'class': 'centered-page-content'}
})
export class WelcomePageComponent implements OnInit {

  public colCount: Number = 3;

  constructor() { 
  }

  ngOnInit(): void {

  }

  cards : Array<WelcomePageCard> = [
    {
      title: "Aktuelles",
      id: "card-1",
      link: '/aktuelles',
      tooltip: "Neuigkeiten rund um das Praxisgeschehen"

    },
    {
      title: "Praxis",
      id: "card-2",
      link: '/praxis',
      tooltip: "Alle Infos zu den Räumlichkeiten der Praxis"
    },
    {
      title: "Team",
      id: "card-3",
      link: '/team',
      tooltip: "Angaben zum Praxisteam"
    },
    {
      title: "Kontakt",
      id: "card-6",
      link: '/kontakt',
      tooltip: "Informationen zu den Kontaktmöglichkeiten und der Anfahrt"
    },

    {
      title: "Sprechzeiten",
      id: "card-5",
      link: '/sprechzeiten',
      tooltip: "Die Sprechzeiten"
    },
    {
      title: "Leistungen",
      id: "card-4",
      link: '/leistungen',
      tooltip: "Die angebotenen Leistungen"
    },
    {
      title: "Impressum",
      id: "card-7",
      link: '/impressum',
      tooltip: "Das notwendige Impressum"
    }
  ]
}

export interface WelcomePageCard {

  title: String;
  id: String;
  link?: String;
  tooltip?: String;

}
