import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'services-card',
  templateUrl: './services-card.component.html',
  styleUrls: ['./services-card.component.css'],
  host: {class: "rounded-element-with-border blurry-element-with-fallback vertical-layout primary-axis-centered secondary-axis-centered"}
})
export class ServicesCardComponent implements OnInit {

  @Input() serviceHeading: string;

  @Input() serviceDescription: string = "";

  @Input() servicePictureUrl: string = "/assets/img/services/teeth-health.jpg";

  public textToggleButtonLabel: string = "?";

  public textToggleButtonTooltip: string = "Klick für mehr ..."

  public isShowingText: boolean = false;

  @HostBinding("class.interactable-card") isInteractable: boolean = false;

  constructor() { }

  ngOnInit(): void {

    if (this.serviceDescription != '') {
      this.isInteractable = true;
    }
  }

  toggleServiceDescription(): void {

    if (this.isShowingText) {
      this.isShowingText = false;
      this.textToggleButtonLabel = "?";
      this.textToggleButtonTooltip = "Klick für mehr ..."
    } else {
      this.isShowingText = true;
      this.textToggleButtonLabel = "X";
      this.textToggleButtonTooltip = "Klick für weniger ..."
    }
  }
}
