<div class="my-menu-bar blurry-element-with-fallback">

    <img [routerLink]="'/startseite'"  src="assets/img/logo_neu.jpg" id="menuBarLogo" alt="Logo Praxis" matTooltip="Zur Startseite" matTooltipClass="normal-text custom-tooltip" matTooltipPosition="below">

    <fa-icon id="menuBarButton" [icon]="faCoffee" [matMenuTriggerFor]="menu" matTooltip="Men&uuml; öffnen" matTooltipClass="normal-text custom-tooltip" matTooltipPosition="below"></fa-icon>

    <div id="titleArea">

        <p id="menuBarTitle" class="page-heading">{{title}}</p>

        <p id="menuBarTitleDescription" class="normal-text" *ngIf="!isSubpageMenuBar">Zahnheilkunde & Kieferorthopädie</p>

    </div>

    <mat-menu #menu="matMenu" overlapTrigger="true">
        <a mat-menu-item class="heading" routerLink="/startseite" >Startseite</a>
        <a mat-menu-item class="heading" routerLink ="/aktuelles" >Aktuelles</a>
        <a mat-menu-item class="heading" routerLink ="/praxis" >Praxis</a>
        <a mat-menu-item class="heading" routerLink ="/team" >Team</a>
        <a mat-menu-item class="heading" routerLink ="/kontakt" >Kontakt</a>
        <a mat-menu-item class="heading" routerLink ="/sprechzeiten" >Sprechzeiten</a>
        <a mat-menu-item class="heading" routerLink ="/leistungen" [matMenuTriggerFor]="services">Leistungen</a>
        <a mat-menu-item class="heading" routerLink ="/impressum" >Impressum</a>
    </mat-menu>

    <mat-menu #services="matMenu">
        <a mat-menu-item class="heading" routerLink="/leistungen/zahnheilkunde">Allg. Zahnheilkunde</a>
        <a mat-menu-item class="heading" routerLink="/leistungen/kieferorthopaedie">Kieferorthopädie</a>
        <a mat-menu-item class="heading" routerLink="/leistungen/faq">FAQ</a>
      </mat-menu>

    <p id="menuBarSubtitle" class="page-sub-heading" *ngIf="isSubpageMenuBar">
        Zahn&auml;rztliche<br> 
        Gemein<wbr>&shy;schafts<wbr>&shy;praxis<br> 
        Dr. Sedlmair
    </p>

</div>

