import { Component, Input, OnInit } from '@angular/core';
import { ngIfAnimation } from 'src/app/shared/animations/ngIfAnimation';

@Component({
  selector: 'question-answer-pair',
  templateUrl: './question-answer-pair.component.html',
  styleUrls: ['./question-answer-pair.component.css'],
  animations: [
    ngIfAnimation
  ]
})
export class QuestionAnswerPairComponent implements OnInit {

  @Input() question: string;

  @Input() answer: string;

  public isExpanded: boolean = false;

  public tooltipText: string = "Klick für die Antwort ...";

  constructor() { }

  ngOnInit(): void {
  }

  public toggleAnswerVisibility():void {

    if (this.isExpanded) {
      this.isExpanded = false; 
      this.tooltipText = "Klick für die Antwort ...";
    } else {

      this.isExpanded = true;
      this.tooltipText = "Klick zum Verbergen der Antwort ..."

    }
  }
}
