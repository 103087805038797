<div class="horizontal-layout primary-axis-centered secondary-axis-centered">

    <div class="office-hours-part rounded-element-with-border blurry-element-with-fallback">

        <h2 class="sub-heading centered-text">Allgemeine Zahnheilkunde</h2>

        <h3 class="sub-sub-heading">Dresden Pillnitz</h3>

        <table class="office-hours-table normal-text">
            <tr>
                <th>Wochentag</th>
                <th>Vormittags</th>
                <th>Nachmittags</th>
            </tr>
            <tr>
                <td>Montag</td>
                <td>7:30 - 12:00 Uhr</td>
                <td>14:00 - 19:00 Uhr</td>
            </tr>
            <tr>
                <td>Dienstag</td>
                <td>7:30 - 12:00 Uhr</td>
                <td>-</td>
            </tr>
            <tr>
                <td>Mittwoch</td>
                <td>7:30 - 12:00 Uhr</td>
                <td>14:00 - 19:00 Uhr</td>
            </tr>
            <tr>
                <td>Donnerstag</td>
                <td>7:30 - 12:00 Uhr</td>
                <td>-</td>
            </tr>
            <tr>
                <td>Freitag</td>
                <td>7:30 - 12:00 Uhr</td>
                <td>-</td>
            </tr>
        </table>

    </div>

    <div class="office-hours-part rounded-element-with-border blurry-element-with-fallback">

        <h2 class="sub-heading centered-text">Kieferorthopädie</h2>

        <h3 class="sub-sub-heading">Dresden Pillnitz</h3>

        <table class="office-hours-table normal-text">
            <tr>
                <th>Wochentag</th>
                <th>Vormittags</th>
                <th>Nachmittags</th>
            </tr>
            <tr>
                <td>Montag</td>
                <td>7:30 - 12:00 Uhr</td>
                <td>-</td>
            </tr>
            <tr>
                <td>Dienstag</td>
                <td>7:30 - 12:00 Uhr</td>
                <td>14:00 - 18:30 Uhr</td>
            </tr>
            <tr>
                <td>Mittwoch</td>
                <td>-</td>
                <td>-</td>
            </tr>
            <tr>
                <td>Donnerstag</td>
                <td>7:30 - 12:00 Uhr</td>
                <td>-</td>
            </tr>
            <tr>
                <td>Freitag</td>
                <td>7:30 - 12:00 Uhr</td>
                <td>-</td>
            </tr>
        </table>

        <mat-divider></mat-divider>

        <h3 class="sub-sub-heading">Zweigpraxis Stolpen</h3>

        <table class="office-hours-table normal-text">
            <tr>
                <th>Wochentag</th>
                <th>Vormittags</th>
                <th>Nachmittags</th>
            </tr>
            <tr>
                <td>Mittwoch</td>
                <td>-</td>
                <td>14:00 - 18:30 Uhr</td>
            </tr>
        </table>

    </div>

</div>
