<div id="servicesOverview" class="horizontal-layout primary-axis-centered secondary-axis-centered">

    <div routerLink="/leistungen/zahnheilkunde" class="rounded-element-with-border blurry-element-with-fallback general-services-part centered-text vertical-layout">

        <h2 class="heading general-services-part-header">Allgemeine Zahnheilkunde</h2>

        <img src="assets/img/services/teeth-health.jpg" title="Symbolbild Allgemeine Zahnheilkunde, Quelle: Nurrohmad, Memed; Link: https://pixabay.com/de/vectors/z%c3%a4hne-karikatur-hygiene-reinigung-1670434/; letzter Abruf: 25.11.2021" class="responsive-picture" alt="Allgemeine Zahlheilkunde Bild">

    </div>

    <div routerLink="/leistungen/kieferorthopaedie" class="rounded-element-with-border blurry-element-with-fallback general-services-part centered-text vertical-layout">

        <h2 class="heading general-services-part-header">Kieferorthopädie</h2>

        <img src="assets/img/services/braces.jpg" title="Symbolbild Kieferorthopädie" class="responsive-picture" alt="Kieferorthopädie Bild">

    </div>

    <div routerLink="/leistungen/faq" class="rounded-element-with-border blurry-element-with-fallback general-services-part centered-text vertical-layout">

        <h2 class="heading general-services-part-header">Häufig gestellte Fragen (FAQ)</h2>

        <img src="assets/img/services/faq.jpg" title="Symbolbild Häufig gestellte Fragen" class="responsive-picture" alt="FAQ Bild">

    </div>

</div>