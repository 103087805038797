import { Component, OnInit } from '@angular/core';
import { QuestionAnswerPair } from './QuestionAnswerPair';
import { QuestionAnswerPairType } from './QuestionAnswerPairType';

@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './frequently-asked-questions.component.html',
  styleUrls: ['./frequently-asked-questions.component.css']
})
export class FrequentlyAskedQuestionsComponent implements OnInit {

  faqPairs: QuestionAnswerPair[] = [

    {
      question: "Wann sollte der Behandlungsbeginn für Kinder sein?",
      answer: "Der Start der Behandlung sollte in der zweiten Wechselgebissphase sein, das heißt, wenn bei Ihrem Kind der Zahnwechsel der Seitenzähne begonnen hat, im Alter von 9-10 Jahren.\n\nEs gibt Ausnahmen, die Frühbehandlung oder die frühe Behandlung, das bedeutet, bestimmte Zahnfehlstellungen können auch frühzeitiger behandelt werden (zum Beispiel bei Kreuzbissverzahnung oder bei Progenie), allerdings nicht vor dem 4. Lebensjahr.",
      type: QuestionAnswerPairType.ORTHODONTIA_QUESTION
    },
    {
      question: "Wie lange dauert die Behandlung?",
      answer: "Die reguläre Behandlung sowie die frühe Behandlung dauern 4 Jahre (16 Quartale).\n\nDie Frühbehandlung dauert 1,5 Jahre (6 Quartale).",
      type: QuestionAnswerPairType.ORTHODONTIA_QUESTION
    },
    {
      question: "Was ist KIG ?",
      answer: "Die Abkürzung KIG steht für \"Kieferorthopädische Indikationsgruppen\" und dient zur Einstufung des Schwergrades der Behandlung für gesetzlich Versicherte.\n\nDie kieferorthopädische Befundeinteilung erfolgt nach 5 Schweregraden, \"KIG 1\" - \"KIG 5\", nur für die Schweregrade \"KIG 3\" - \"KIG 5\" werden von den gesetzlichen Krankenkassen die Kosten zu 80% übernommen. Wird gleichzeitig ein zweites Kind behandelt, übernimmt die Krankenkasse 90% der Kosten. ",
      type: QuestionAnswerPairType.ORTHODONTIA_QUESTION
    },
    {
      question: "Welche Kosten entstehen? ",
      answer: "Die Krankenkasse übernimmt 80% der Behandlungskosten ab KIG-Einstufung 3, quartalsweise erfolgt die Abrechnung des Eigenanteils von 20 %. Bei erfolgreichem Abschluss der Behandlung, in der Regel nach 4 Jahren, erstattet Ihnen die Krankenkasse den gezahlten Eigenanteil nach Einreichung der Abschlussbescheinigung.\n\nWird gleichzeitig ein zweites Kind behandelt, übernimmt die Krankenkasse 90% der Behandlungskosten, der Eigenanteil beträgt 10%.",
      type: QuestionAnswerPairType.ORTHODONTIA_QUESTION
    },
    {
      question: "Welche zusätzlichen Kosten können entstehen?",
      answer: "Grundsätzlich besteht ein Anspruch auf eine zuzahlungsfreie Behandlung Ihres Kindes.\nDie Krankenkassen sind an ein Wirtschaftlichkeitsgebot gebunden, deshalb werden oft die Kosten für andere und neue Diagnostik- und Therapiemethoden nicht übernommen (z.B. Funktionsanalyse, vorprogrammierte Brackets, Glattflächenversiegelung, indirektes Kleben).\n\nVor Beginn der Behandlung erhalten Sie einen Kostenvoranschlag zu Mehrkosten und außervertraglichen Leistungen. Sie können entscheiden, ob Sie diese vorgeschlagenen zusätzlichen Leistungen wünschen.\n\nBestimmte Krankenkassen bieten ihren Versicherten einen Zuschuss zu bestimmten Leistungen (z. B. bei der Glattflächenversiegelung) an.",
      type: QuestionAnswerPairType.ORTHODONTIA_QUESTION
    },
    {
      question: "Wie läuft eine kieferorthopädische Behandlung ab?",
      answer: "Zu Beginn steht immer eine Untersuchung, dabei wird der kieferorthopädische Behandlungsbedarf ermittelt: welche Zahnstellungsfehler bestehen, wie stehen die Kiefer zueinander, bestehen Abweichungen im Zahndurchbruch, sind die bleibenden Zähne angelegt, bestehen Defizite beim Sprechen und Schlucken. Es erfolgen Informationen zu Therapiemöglichkeiten, Behandlungsdauer und Kosten.\n\nSoll eine Behandlung stattfinden, so werden bei einem weiteren Termin die Unterlagen für eine umfangreiche Behandlungsplanung erstellt: nochmalige ausführliche Untersuchung der Mundhöhle, Anfertigung von Fotos, Abdrucknahme von Ober- und Unterkiefer mit Bissregistrierung, Anfertigung einer Röntgenübersichtsaufnahme (OPG) sowie einer seitlichen Röntgenaufnahme (Fernröntgen). Eventuell ist zusätzliche Diagnostik notwendig, bei verlagerten Zähnen (3-D-Aufnahme), oder eine Funktionsanalyse bei Kiefergelenkproblemen, diese Kosten übernimmt die gesetzliche Krankenkasse nicht.\n\nAuf dieser Grundlage wird der kieferorthopädische Behandlungsplan aufgestellt und durch den Behandler bei der gesetzlichen Krankenkasse zur Genehmigung eingereicht.\n\nBei dem nächsten Termin wird die Behandlungsplanung besprochen, es erfolgt eine Aufklärung über die erhobenen Befunde, die Behandlungsnotwendigkeit, das Behandlungsziel, alternative Therapiemöglichkeiten, das Risiko der Behandlung, die Behandlungskosten.\n\nDie Behandlung kann beginnen, wenn die Genehmigung durch die Krankenkasse/ Zustimmung der Versicherung vorliegt und alle offenen Fragen geklärt sind. ",
      type: QuestionAnswerPairType.ORTHODONTIA_QUESTION
    },
    {
      question: "Was kann der jugendliche Patient selbst für eine erfolgreiche Behandlung beitragen?",
      answer: "Ganz wichtig, und an erster Stelle, steht eine aktive Mitarbeit des Patienten, die eingesetzten Geräte sind entsprechend den Anweisungen zu tragen. Dazu gehört unbedingt eine gute Mundhygiene sowie die Reinigung der herausnehmbaren Geräte mindestens zweimal täglich mit eigener Zahnbürste, ohne Zahncreme - ein Spülmittel kann verwendet werden, danach gut abspülen. Zusätzlich können Reinigungstabletten verwendet werden.\n\nFür Patienten mit festsitzenden Apparaturen gelten erhöhte Anforderungen an die Mundhygiene und an die Apparaturenpflege. Brackets und Drähte bilden Schlupfwinkel und Nischen, wo Nahrungsreste und Zahnbelag zurückbleiben können. Die Mundhöhlenbakterien im Zahnbelag bilden Säuren, die den Zahnschmelz zerstören, zunächst zu Entkalkungen führen (weiße Flecken um die Brackets), im ungünstigen Fall auch zu Karies (Loch im Zahn).\n\nWir erklären in der Praxis die spezielle Reinigungstechnik für festsitzende Apparaturen, geben Informationen zur Anwendung von Hilfsmitteln und bieten zusätzlich Vorsorgemaßnahmen wie Glattflächenversiegelung und professionelle Zahnreinigung an.\n\nWir empfehlen die Fortsetzung der regelmäßigen Prophylaxe (IP1-IP4) über den Hauszahnarzt und die regelmäßige Anwendung von fluoridhaltiger Zahncreme, fluoridhaltigen Mundspüllösungen sowie einmal wöchentlich die Durchführung einer häuslichen Intensiv-Fluorprophylaxe mit Elmex Gelee (rezeptpflichtig).\n\nDie Krankenkassen übernehmen die Kosten für das Fluorpräparat Elmex Gelee, es gibt von manchen Kassen Kostenzuschüsse für die Glattflächenversiegelung oder die professionelle Zahnreinigung.",
      type: QuestionAnswerPairType.ORTHODONTIA_QUESTION
    },

  ]

  /**
   * The available question types. The value is taken from the enum {@link QuestionAnswerPairType}. 
   */
  public faqQuestionTypes: any[] = [
    {
      label: "Fragen KFO",
      value: "orthodontia_questions"
    },
    {
      label: "Fragen Zahnheilkunde",
      value: "dentistry_questions"
    }
  ]

  public chosenQuestionType: string = QuestionAnswerPairType.ORTHODONTIA_QUESTION;

  constructor() {}

  ngOnInit(): void {}
}
