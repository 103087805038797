import { animate, style, transition, trigger } from "@angular/animations";

export const ngIfAnimation = trigger(
    'ngIfAnimation',
    [
    // tranistion when the element enters the DOM using *ngif
      transition(
        ':enter', [
          style({opacity: 0}),
          animate("500ms ease-in-out", style({opacity: 1}))
        ]
    )
  ,
  // tranistion when the element leaves the DOM using *ngif
      transition(
        ':leave', [
        style({opacity: 1}),
        animate("500ms ease-in-out", style({opacity: 0}))
      ]
    )
    ]
  ) 