import { Component, OnInit } from '@angular/core';
import { Service } from '../Service';

@Component({
  selector: 'app-orthodontia-services',
  templateUrl: './orthodontia-services.component.html',
  styleUrls: ['./orthodontia-services.component.css']
})
export class OrthodontiaServicesComponent implements OnInit {

  public services: Array<Service> = [

    {
      serviceHeading: "Frühbehandlung",
      serviceDescription: "Bei ausgeprägten Zahn- und Kieferfehlstellungen, wie einem seitlichen oder frontalen Kreuzbiss, einem offenen Biss von mehr als 4 mm oder weit vorstehenden Zähnen (ausgeprägte Protrusion der Frontzähne von mehr als 9 mm) ist eine Behandlung bereits im Milchgebiss ab 4. Lebensjahr möglich.\n\nWir setzen erste herausnehmbare Geräte ein, um mit einer Behandlung das richtige Kauen und Abbeißen zu erreichen. Fehlfunktionen und Fehlentwicklungen schon frühzeitig zu stoppen und eine eventuelle Übertragung in die bleibende Dentition zu verhindern, ist Ziel dieser frühen Behandlung.\n\nMit kleinen Maßnahmen kann in manchen Fällen eine spätere aufwendige Behandlung gar nicht erst notwendig werden.\n\nHäufig nutzen wir bei Sprach -und Schluckstörungen die unterstützende Therapie durch den Sprachtherapeuten. Der Kieferorthopädie ist berechtigt, eine Heilmittelverordnung auszustellen bei gestörter Aussprache und falscher Schluckfunktion.\n\nFür die Frühbehandlung (Dauer 1,5 Jahre) ist eine Genehmigung durch die Krankenkasse notwendig.",
      servicePictureUrl: "assets/img/services/orthodontia/early_treatment.jpg"
    },
    {
      serviceHeading: "Lückenhalter",
      serviceDescription: "Wenn Milchzähne schon frühzeitig durch Karies zerstört werden und entfernt werden müssen, geht damit die natürliche Platzhalterfunktion des Milchzahnes für den bleibenden Nachfolger verloren. In die entstandene Zahnlücke kippen die Nachbarzähne und wandern auf, der durchbrechende bleibende neue Zahn findet keinen Platz mehr.\n\nDiese für die gesamte Gebissentwicklung ungünstige Situation kann mit der rechtzeitigen Eingliederung einer kleinen Zahnspange/ Lückenhalter verhindert werden. Die gesetzliche Krankenkasse übernimmt vollständig die Kosten.",
      servicePictureUrl: "assets/img/services/orthodontia/gap.jpg"
    },
    {
      serviceHeading: "Herausnehmbare Behandlungsgeräte",
      serviceDescription: "Die Behandlung mit herausnehmbaren Geräten (aktive Platten, funktionskieferorthopädische Geräte) findet in der zweiten Wechselgebissphase, im Alter von ca. 9-12 Jahren statt.\n\nEine Behandlung mit einer aktiven Platte geschieht mit sanftem Druck auf die Zahnkrone, die Zahnwurzel behält ihre Stellung. Durch Nachstellen der Schrauben und Federn im Gerät kann der Kiefer geweitet, können Lücken geöffnet werden.  Dazu ist aktive Mitarbeit des Patienten unabdingbare Voraussetzung, die Geräte müssen ständig getragen werden, einzige Ausnahme sind Pausen durch Essen und Sport.\n\nMit einem funktionskieferorthopädischen Gerät kann während der Wachstumsphase eine fehlerhafte Bisslage (Rückbisslage des Unterkiefers/ Distalbiss) korrigiert werden. Das Gerät (Aktivator, Bionator) liegt nur locker im Mund und übt keine aktiven Kräfte aus. Es wird nur aktiv, wenn der Patient hineinbeißt. Für die Einstellung der korrekten Verzahnung wird nach Abdrucknahme ein Konstruktionsbiss mit dem Bisslageziel erstellt. Durch das Einbeißen in das Gerät werden die Kräfte der Kau-, Wangen-, Zungen-, und Lippenmuskulatur auf das Kiefergelenk, den Knochen und den Zahnhalteapparat übertragen.\n\nVoraussetzungen für den Behandlungserfolg sind die Einhaltung der Tragezeit (in der Regel 14 Stunden, nachmittags und nachts) und ein noch vorhandenes ausreichendes Wachstumspotential der Kiefergelenke.\n\nDie gesetzliche Krankenkasse übernimmt die Kosten der Behandlung (80%) bei entsprechender KIG-Einstufung.",
      servicePictureUrl: "assets/img/services/orthodontia/removeable_brace.jpg"
    },
    {
      serviceHeading: "Festsitzende Apparaturen",
      serviceDescription: "Für die Behandlung mit festsitzenden Apparaturen müssen die bleibenden Zähne durchgebrochen sein, Beginn im Alter von ca.12 Jahren.\n\nMit festsitzenden Apparaturen, dabei werden Brackets fest auf die Zahnoberfläche aufgeklebt, Bänder auf die Backenzähne fest einzementiert, können Zähne körperlich bewegt werden (Krone und Wurzel). Die Brackets und Bänder nehmen einen hochelastischen Drahtbogen auf, der einen geringen, ständigen und gleichmäßigen Druck auf die Zähne ausübt und die Zähne in die geplante Richtung bewegt. Der permanent wirkende Druck wird mit sehr geringen dosierten Kräften erzeugt, so dass keine Schäden an den Zähnen oder am Kiefer entstehen.\n\nHäufig wird zur Verankerung und als Aufbissschutz für die unteren Brackets im Oberkiefer ein spezieller Gaumenbogen benutzt, der fest an den Bändern der Backenzähne eingesetzt ist.\n\nDie festsitzende Apparatur wirkt ständig und ist weitgehend unabhängig von der Mitarbeit. Unabdingbare Voraussetzung sind ein kariesfreies Gebiss ohne Mineralisationsstörungen und eine exzellente Mund -und Apparaturenpflege.\n\nDie gesetzliche Krankenkasse übernimmt die Kosten der Behandlung (80%) bei entsprechender KIG-Einstufung. Die Behandlung kann angenehmer, weniger schmerzhaft und sicherer gestaltet werden durch zusätzliche Angebote, deren Kosten die Krankenkasse nur teilweise oder nicht übernimmt.",
      servicePictureUrl: "assets/img/services/orthodontia/fixed_brace.jpg"
    },
    {
      serviceHeading: "Prophylaxe",
      serviceDescription: "Wir legen großen Wert auf die Gesunderhaltung der Zähne während der kieferorthopädischen Therapie. Deshalb bieten wir im Rahmen der Behandlung mit festsitzenden Apparaturen ein speziell abgestimmtes Prophylaxeprogramm mit Glattflächenversiegelung, Reinigung mit Air flow sowie Fluortouchierung an.",
      servicePictureUrl: "assets/img/services/orthodontia/dental_care.jpg"
    },
    {
      serviceHeading: "Beratung",
      serviceDescription: "",
      servicePictureUrl: "assets/img/services/orthodontia/consulting.jpg"
    }, 

  ];

  constructor() { }

  ngOnInit(): void {

    this.services = this.services.sort((serviceA,serviceB) => {
      return serviceA.serviceHeading.localeCompare(serviceB.serviceHeading);
    })
    
  }

}
