import { Component, Input, OnInit } from '@angular/core';
import { Service } from '../Service';

@Component({
  selector: 'services-grid',
  templateUrl: './services-grid.component.html',
  styleUrls: ['./services-grid.component.css']
})
export class ServicesGridComponent implements OnInit {

  @Input() services: Array<Service>;

  constructor() { }

  ngOnInit(): void {
  }

}
