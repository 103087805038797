import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './components/contact/contact.component';
import { FacilityComponent } from './components/facility/facility.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { NewsComponent } from './components/news/news.component';
import { OfficeHoursComponent } from './components/office-hours/office-hours.component';
import { DentistryServicesComponent } from './components/services/dentistry-services/dentistry-services.component';
import { FrequentlyAskedQuestionsComponent } from './components/services/frequently-asked-questions/frequently-asked-questions.component';
import { OrthodontiaServicesComponent } from './components/services/orthodontia-services/orthodontia-services.component';
import { ServicesComponent } from './components/services/services.component';
import { TeamComponent } from './components/team/team.component';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';

const routes: Routes = [
  // welcome page
  {
    path: 'startseite',
    component: WelcomePageComponent,
  },

  // news page
  {
    path: 'aktuelles',
    component: NewsComponent,
  },

  // page about facility
  {
    path: 'praxis',
    component: FacilityComponent,
  },

  // team page
  {
    path: 'team',
    component: TeamComponent,
  },

  // services page
  {
    path: 'leistungen',
    component: ServicesComponent,
  },

  // Allgemeine Zahnheilkunde
  {
    path: 'leistungen/zahnheilkunde',
    component: DentistryServicesComponent,
  },

  // Kieferorthopädie
  {
    path: 'leistungen/kieferorthopaedie',
    component: OrthodontiaServicesComponent,
  },

  // FAQ
  {
    path: 'leistungen/faq',
    component: FrequentlyAskedQuestionsComponent,
  },

  // office hours page
  {
    path: 'sprechzeiten',
    component: OfficeHoursComponent,
  },

  // contact page
  {
    path: 'kontakt',
    component: ContactComponent,
  },

  // contact page
  {
    path: 'impressum',
    component: ImprintComponent,
  },

  // default route
  {
    path: '',
    component: WelcomePageComponent,
    pathMatch: 'full',
  },

  // wildcard route
  {
    path: '**',
    component: WelcomePageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
