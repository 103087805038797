import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FacilityElement } from '../FacilityElement';

@Component({
  selector: 'facility-part-showroom',
  templateUrl: './facility-part-showroom.component.html',
  styleUrls: ['./facility-part-showroom.component.css']
})
export class FacilityPartShowroomComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {facilityElement: FacilityElement}) { }

  ngOnInit(): void {
  }

}
