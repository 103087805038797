import { Component, Input, OnInit } from '@angular/core';
import { ngIfAnimation } from 'src/app/shared/animations/ngIfAnimation';
import { __param } from 'tslib';
import { NewsArticle } from './NewsArticle';

@Component({
  selector: 'news-article',
  templateUrl: './news-article.component.html',
  styleUrls: ['./news-article.component.css'],
  animations: [
    ngIfAnimation
  ]
})
export class NewsArticleComponent implements OnInit {

  @Input() newsArticle: NewsArticle;

  public showFullText: boolean = false;

  public tooltipText: string = "Klick für mehr ..."

  constructor() { }

  ngOnInit(): void {
  }

  toggleFullTextVisibility(): void {

    if (this.showFullText == true) {
      this.showFullText = false;
      this.tooltipText = "Klick für mehr ...";
    } else {

      this.showFullText = true;
      this.tooltipText = "Klick für weniger ..."

    }
  }
}
