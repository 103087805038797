import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationEnd, Router } from '@angular/router';
import { faBars, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: [
    './menu-bar.component.css',
  ]
})
export class MenuBarComponent implements OnInit {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input() title: string;

  @Input() isSubpageMenuBar: boolean;

  faCoffee: IconDefinition = faBars;

  private routeChangeSubscription: Subscription;

  constructor(private router: Router) { }

  ngOnInit(): void {

    // when a route change is detected, close the menu (default mode, but not for sub menus that can trigger a route change as well, so we need to have this here)
    this.routeChangeSubscription = this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        
        this.trigger.closeMenu();

      }
    });
  }

  ngOnDestroy(): void {

    this.routeChangeSubscription.unsubscribe();

  }

}
