import { Component, OnInit } from '@angular/core';
import { NewsArticlesService } from 'src/app/shared/services/news-articles.service';
import { NewsArticle } from './news-article/NewsArticle';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  public newsArticles: Array<NewsArticle> = new Array<NewsArticle>();

  constructor(
    private newsArticleService: NewsArticlesService) { }

  ngOnInit(): void {

    this.newsArticleService.getNewsArticles().subscribe({
      next: (result) => {
        this.newsArticles = result;
      }
    })
  }

}
