import { Component, OnInit } from '@angular/core';
import { faEnvelope, faPhoneSquareAlt, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'contact-page',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  host: {'class': 'centered-page-content  vertical-layout primary-axis-centered secondary-axis-centered'}
})
export class ContactComponent implements OnInit {

  public phoneIcon: IconDefinition = faPhoneSquareAlt;

  public mailIcon: IconDefinition = faEnvelope;

  constructor() { }

  ngOnInit(): void {
  }

}
