<div id="pageInteractionBar" class="rounded-element-with-border blurry-element-with-fallback horizontal-layout secondary-axis-centered">

    <button [routerLink]="'/leistungen/kieferorthopaedie'" mat-raised-button>
        Zur Kieferorthopädie
    </button>     

    <button [routerLink]="'/leistungen/faq'" mat-raised-button>
        Zum FAQ
    </button>     
        
</div>

<services-grid [services]="services"></services-grid>
