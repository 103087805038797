import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'personal-box',
  templateUrl: './personal-box.component.html',
  styleUrls: ['./personal-box.component.css']
})
export class PersonalBoxComponent implements OnInit {

  @Input() fullName: string;

  @Input() jobTitle: string;

  @Input() imageUrl: string;

  constructor() { }

  ngOnInit(): void {
  }

}
