import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewsArticle } from 'src/app/components/news/news-article/NewsArticle';

@Injectable({
  providedIn: 'root'
})
export class NewsArticlesService {

  constructor(private httpClient: HttpClient) { }

  localNewsArticlesUrl: string = "assets/data/news_articles.json";

  /**
   * Get all news articles from a simple JSON file. 
   */
    getNewsArticles(): Observable<NewsArticle[]>{

    return this.httpClient.get<NewsArticle[]>(this.localNewsArticlesUrl);

  }

}
